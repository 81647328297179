import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Product from "./pages/js/product";
import Login from "./pages/js/login";
import Belt from "./pages/js/belt";
import Root from "./views/js/root";
import AllProducts from "./views/js/allProducts";
import EditProduct from "./views/js/editProduct";
import AddProduct from "./views/js/addProduct";
import HomePage from "./views/js/homePage";
// import { Navigate } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Protected from "./components/js/protected";

function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" Component={Root} />
        <Route path="/product-belt/ent/SP/iRcode=220111833862-0001173307K606052" Component={Belt}/>
        <Route path="/admin-panel" Component={Login}/>
        <Route path="/product/:productId/:id/:batchId" Component={Product}/>
	  {/* <Route path="/admin-panel/dashboard" element={<Protected><HomePage/></Protected>}/>
        <Route path="/admin-panel/all_products" element={<Protected><AllProducts/></Protected>}/>
        <Route path="/admin-panel/add_product" element={<Protected><AddProduct/></Protected>}/>
        <Route path="/admin-panel/edit_product/:productId/:id/:batchId" element={<Protected><EditProduct/></Protected>}/> */}
        <Route path="/admin-panel/dashboard" element={<HomePage/>}/>
        <Route path="/admin-panel/all_products" element={<AllProducts/>}/>
        <Route path="/admin-panel/add_product" element={<AddProduct/>}/>
        <Route path="/admin-panel/edit_product/:productId/:id/:batchId" element={<EditProduct/>}/>
      </Routes>
    </Router>
  );
}

export default App;
