import React, {useEffect} from "react";
import "../css/homePage.css";
import Sidebar from "../../components/js/sidebar";
import 'animate.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function HomePage() {

  const navigate = useNavigate();

/*  useEffect(() => {
    axios.post(`${process.env.REACT_APP_URL}/users-endpoints/validate_token`, {
      token: localStorage.getItem('token')
    })
    .catch((err) => {
      navigate('/');
    });
  }, []);
*/
  return (
    <div className="homePage">
      <Sidebar />
      <h1 className="animate__animated animate__fadeInLeft title">Welcome to the Admin Panel</h1>
      <p className="animate__animated animate__fadeInRight title">Here you can add, edit and delete products</p>
    </div>
  );
}
